/* You can add global styles to this file, and also import other style files */

/* Importing Bootstrap SCSS file. */
@import "bootstrap/scss/bootstrap";

/* Importing Datepicker SCSS file. */
@import "node_modules/ngx-bootstrap/datepicker/bs-datepicker";

body {
    background-color: #17afb5;
    height: 100vh;
    width: 100vw;

    // .ecEmbedWrapper.dockOut {
    //     width: 90vw;
    // }
}